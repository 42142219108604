@import url('https://fonts.googleapis.com/css2?family=Jersey+25&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 1em;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
}
.App{
    background: #f0f0f0;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
}
.fallback-div{
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.fallback-div svg{
    width: 150px;
    height: 150px;
}
.loading-fallback{
    color: #3498db;
    font-size: 2em;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#Navigation {
  width: 290px;
  height: max-content;
  background: #1c2631;
  display: none;
  z-index: 9999;
  align-items: center;
  flex-direction: column;
  position: absolute;
  border-bottom-right-radius: 10px;
  padding-bottom: 15px;
}
.company-logo {
  height: 100px;
  width: 100%;
  background: #1e1e1e;
  display: flex;
  justify-content: center;
}
.nav-links {
  margin: 20px 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  line-height: 2;
  width: 70%;
}
.nav-links .nav-link {
  margin: 10px 0;
}

.nav-links .nav-link .link {
  padding: 5px;
  color: #3498db;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nav-link .link svg{
  font-size: 1.3em;
  margin-left: 5px;
}
.nav-links .nav-link .link.curr-page{
  color: #e74c3c;
}
.nav-links .link:hover {
  color: #f34f3a;
}
.handler-div {
  width: 90%;
  margin-top: 10px;
  padding: 0 5px;
}
.service-handle {
  width: 80%;
  border: none;
  outline: none;
  background: #36495d;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  padding: 5px 40px 5px 10px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.service-handle svg {
  font-size: 1em;
  padding-right: 5px;
}
.service-handle:hover {
  background: #f34f3a;
  letter-spacing: 1px;
}
.sub-links {
  width: 90%;
}
.sub-links .sub-link {
  text-decoration: none;
  display: block;
  padding: 5px;
  margin: 15px 0;
}
.sub-link .link {
  padding: 5px;
  color: #3498db;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.sub-link .link.curr-page{
  color: #e74c3c;
}
.toggle-div {
  position: absolute;
  z-index: 20;
  right: 20px;
  top: 10px;
}
.toggle-div .toggle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #363636;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
}
.toggle-btn svg {
  font-size: 1.3em;
}
.cta-button-div {
  display: none;
}
/* Social Icons Div */
.horizon {
  width: 90%;
  height: 1px;
  padding: 2px;
  background: #015f9d;
  border: none;
  outline: none;
}

@media screen and (min-width: 768px) {
  #Navigation {
    position: relative;
    width: 100%;
    height: 60px;
    background: #2c3e50;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-radius: 0;
    padding-bottom: 0;
  }
  .company-logo {
    height: 100%;
    width: 20vw;
    background: #1e1e1e;
    display: flex;
    justify-content: center;
  }
  .logo-img {
    width: 100%;
    height: 100%;
  }
  .nav-links {
    margin: 20px 0;
    padding: 0 40px;
    list-style: none;
    display: flex;
    justify-content: flex-end;
  }
  .nav-links .nav-link {
    margin: 5px 10px;
  }
  .nav-links .link {
    padding: 5px 25px;
    text-decoration: none;
    color: #3498db;
    position: relative;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: center;
  }
  .nav-links .nav-link .link.curr-page{
    color: #e74c3c;
  }
  .service-link {
    position: relative;
    cursor: pointer;
  }
  .service-link svg{
    font-size: 1.5em;
    margin-left: 2px;
  }
  .sub-links {
    width: 290px;
    position: absolute;
    z-index: 9999;
    top: 46px;
    background: #36495d;
    line-height: 2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px 10px 30px;
  }
  .sub-links .sub-link {
    text-decoration: none;
    display: block;
    padding: 5px;
    margin: 5px 0;
  }
  .cta-button-div {
    width: 170px;
    margin-right: 2%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cta-button-div .cta-btn {
    background: #e74c3c;
    color: white;
    padding: 7px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 1s ease;
    text-decoration: none;
    font-weight: 600;
  }
  .cta-button-div .cta-btn:hover {
    background: #c0392b;
  }
  .toggle-div {
    display: none;
  }
}

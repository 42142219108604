.social-icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}
.social-icon-link {
  margin: 0 20px;
  font-size: 1.5em;
  color: #3498db;
  cursor: pointer;
  transition: all 0.3s ease;
}
.social-icon-link:hover {
  color: #e74c3c;
}

#Footer{
    width: 100%;
    padding: 0 3%;
}
.footer-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 1em 0;
    border-radius: 8px;
}
.footer-title{
    text-align: center;
    color: #e74c3c;
    font-size: 1.4em;
}
.footer-desc{
    color: #374151;
}
.footer-title-sm{
    text-align: center;
    font-size: 1.2em;
    color: #172554;
    padding: 10px 0;
}
.links-div{
    width: 250px;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
}
.links-div .link-element{
    font-size: 1em;
    display: flex;
    align-items: center;
    margin: 5px 0;
    color: #3498DB;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
}
.link-element svg{
    margin-right: 5px;
}
.link-element:hover{
    letter-spacing: 1px;
    color: #172554;
}
.link-element:hover svg{
    color: #e74c3c;
}
.contact-info-div {
    margin: 6px 0;
}
.links-div strong{
    color: #1e1b4b;
}
.copyright{
    text-align: center;
    color: #0b0b0b;
    font-size: 1em;
    padding: 10px 0;
    background: #1e1e1e;
    margin-bottom: 15px;
    border-radius: 8px;
}
.copyright-text{
    color: #ccc;
    padding: 0 5px;
}
.foo-link{
    color: #3498DB;
    text-decoration: none;
    margin: 0 10px;
    transition: all 0.5s ease-in-out;
}
.foo-link:hover{
    color: #e74c3c;
}
.p-tag{
    padding-left: 10px;
}
@media only screen and (min-width:600px) and (max-width: 899px){
    .footer-section{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        box-sizing: border-box;
    }
    .footer-container{
        border-radius: 8px;
    }
    .comp-desc{
        width: 100%;
    }
    .usefull-links,
    .our-services{
        width: 49%;
    }
    .contact-info{
        width: 100%;
    }
    .copyright{
        width: 100%;
        border-radius: 8px;
    }
}
@media only screen and (min-width:900px) and (max-width: 1023px){
    .footer-section{
        display: flex;
        flex-wrap: wrap;
        padding: 0 3%;
        justify-content: space-between;
        box-sizing: border-box;
    }
    .footer-container{
        border-radius: 8px;
    }
    .usefull-links,
    .our-services{
        width: 30%;
    }
    .contact-info{
        width: 37%;
    }
    .copyright{
        width: 100%;
        border-radius: 8px;
    }
}
@media only screen and (min-width:1024px){
    .footer-section{
        display: flex;
        flex-wrap: wrap;
        padding: 0 3%;
        justify-content: space-between;
        box-sizing: border-box;
    }
    .footer-container{
        border-radius: 8px;
    }
    .usefull-links,
    .our-services{
        width: 30%;
    }
    .contact-info{
        width: 37%;
    }
    .copyright{
        width: 100%;
        border-radius: 8px;
    }
}
